<template>
  <div class="visited-seller" :title="data.name">
    <transition name="visited-seller__delete" :duration="transitionDuration">
      <div v-if="isDeleteMode" class="visited-seller__delete">
        <v-delete-icon />
      </div>
    </transition>
    <div :class="['visited-seller__image', { 'visited-seller__image--delete': isDeleteMode }]">
      <img width="36" height="36" :src="data.img" />
    </div>
    <p class="visited-seller__name">{{ data.keyword }}</p>
    <div class="visited-seller__badge">
      <bl-emblem-av v-if="data.super_seller" variation="new_superseller_icon" size="small" />
      <bl-emblem-av v-else-if="data.brand_seller" variation="bukamall_icon" size="small" />
    </div>
  </div>
</template>

<script>
// override emblem component because Mothership doesn't support the latest bazaar css yet
import BlEmblemAv from './BlEmblem.vue';
import VDeleteIcon from './VDeleteIcon.vue';

export default {
  name: 'VVisitedSeller',
  components: { BlEmblemAv, VDeleteIcon },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      transitionDuration: 200,
    };
  },
  computed: {
    isDeleteMode() {
      return window?.STORE?.state?.whitebox?.isDeleteMode ?? false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

.visited-seller {
  display: flex;
  align-items: center;
  &__image {
    width: 36px;
    height: 36px;
    img {
      border-radius: 50%;
      border: 1px solid #f2f2f2;
    }
  }
  &__name {
    width: 100%;
    margin: 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $text-secondary;
    font-size: $font-size-16;
    font-weight: $font-weight-regular;
  }
  &__badge {
    min-width: 24px;
    max-width: 24px;
    margin-left: auto;
    margin-right: 8px;
  }
}
</style>

<style lang="scss">
/* global style overrides for vue-autosuggest */
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

.v-omnisearch__results--revamp {
  .-visited_sellers {
    .autosuggest-result-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
    }
    .autosuggest__result-item {
      box-sizing: border-box;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
      max-width: 50%;
      padding: 8px !important;
    }
    .visited-seller__delete {
      position: absolute;
      &-enter-active,
      &-leave-active {
        transition: opacity $speed-fast $ease-in-out-regular;
      }
      &-enter,
      &-leave-to {
        opacity: 0;
      }
    }
    .visited-seller__image {
      transition: margin $speed-fast $ease-in-out-regular;
      &--delete {
        margin-left: 28px;
        transition: margin $speed-fast $ease-in-out-regular;
      }
    }
  }
}
</style>
