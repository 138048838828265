import tracker from 'lib/tracker';

export const STATE_BEFORE_TYPING = 'before_typing';
export const STATE_FOCUS_TYPING = 'focus_typing';

export const TRACKER_ACTIONS = {
  // before typing actions
  SEARCH_BAR: 'search_bar',
  BACK: 'back',
  LAST_SEARCHED_KEYWORD: 'last_searched/keyword',
  DELETE_LAST_SEARCHED_ALL_KEYWORD: 'delete_last_searched/all_keyword',
  DELETE_LAST_SEARCHED_KEYWORD: 'delete_last_searched/keyword',
  LAST_SEEN_CLICK_PRODUCT: 'last_seen/click_product',
  LAST_SEEN_CLICK_SELLER: 'last_seen/seller',
  DELETE_LAST_SEEN: 'delete_last_seen',
  DELETE_LAST_SEEN_ALL: 'delete_last_seen/all',
  DELETE_LAST_SEEN_PRODUCT: 'delete_last_seen/product',
  DELETE_LAST_SEEN_SELLER: 'delete_last_seen/seller',
  CAMPAIGN: 'campaign',
  TRENDING_KEYWORD: 'trending/keyword',
  // focus typing actions
  ENTER_KEYBOARD: 'enter_keyboard',
  DOPE_SUGGESTION: 'dope_suggestion',
  BUKAMALL_SUGGESTION: 'bukamall_suggestion/seller',
  HISTORICAL_SUGGESTION: 'historical_suggestion/keyword',
  DELETE_HISTORICAL_SUGGESTION: 'historical_suggestion/delete',
  CATEGORY_SUGGESTION: 'category_suggestion/keyword',
  ORGANIC_SUGGESTION: 'organic_suggestion/keyword',
  SELLER_SUGGESTION: 'seller_suggestion/seller',
  SELLER_SUGGESTION_ALL: 'seller_suggestion/lihat_semua',
};

export const TRACKER_OBJECT = {
  [STATE_BEFORE_TYPING]: {
    [TRACKER_ACTIONS.SEARCH_BAR]: null,
    [TRACKER_ACTIONS.BACK]: null,
    [TRACKER_ACTIONS.LAST_SEARCHED_KEYWORD]: 'keyword',
    [TRACKER_ACTIONS.DELETE_LAST_SEARCHED_KEYWORD]: 'keyword',
    [TRACKER_ACTIONS.DELETE_LAST_SEARCHED_ALL_KEYWORD]: null,
    [TRACKER_ACTIONS.LAST_SEEN_CLICK_PRODUCT]: 'id',
    [TRACKER_ACTIONS.LAST_SEEN_CLICK_SELLER]: 'id',
    [TRACKER_ACTIONS.DELETE_LAST_SEEN]: null,
    [TRACKER_ACTIONS.DELETE_LAST_SEEN_ALL]: null,
    [TRACKER_ACTIONS.DELETE_LAST_SEEN_PRODUCT]: 'id',
    [TRACKER_ACTIONS.DELETE_LAST_SEEN_SELLER]: 'id',
    [TRACKER_ACTIONS.CAMPAIGN]: 'name',
    [TRACKER_ACTIONS.TRENDING_KEYWORD]: 'keyword',
  },
  [STATE_FOCUS_TYPING]: {
    [TRACKER_ACTIONS.ENTER_KEYBOARD]: 'keyword',
    [TRACKER_ACTIONS.DOPE_SUGGESTION]: 'keyword',
    [TRACKER_ACTIONS.BUKAMALL_SUGGESTION]: 'id',
    [TRACKER_ACTIONS.HISTORICAL_SUGGESTION]: 'keyword',
    [TRACKER_ACTIONS.DELETE_HISTORICAL_SUGGESTION]: 'keyword',
    [TRACKER_ACTIONS.CATEGORY_SUGGESTION]: 'id',
    [TRACKER_ACTIONS.ORGANIC_SUGGESTION]: 'keyword',
    [TRACKER_ACTIONS.SELLER_SUGGESTION]: 'id',
    [TRACKER_ACTIONS.SELLER_SUGGESTION_ALL]: null,
  },
};

export const getActionFromSuggestion = suggestion => {
  const { type, keywordBeforeSelected, isDelete } = suggestion;
  switch (true) {
    // before typing actions
    case type === 'keywords_history' && !keywordBeforeSelected && !isDelete:
      return TRACKER_ACTIONS.LAST_SEARCHED_KEYWORD;
    case type === 'keywords_history' && !keywordBeforeSelected && isDelete:
      return TRACKER_ACTIONS.DELETE_LAST_SEARCHED_KEYWORD;
    case type === 'products' && !isDelete:
      return TRACKER_ACTIONS.LAST_SEEN_CLICK_PRODUCT;
    case type === 'visited_sellers' && !isDelete:
      return TRACKER_ACTIONS.LAST_SEEN_CLICK_SELLER;
    case type === 'products' && isDelete:
      return TRACKER_ACTIONS.DELETE_LAST_SEEN_PRODUCT;
    case type === 'visited_sellers' && isDelete:
      return TRACKER_ACTIONS.DELETE_LAST_SEEN_SELLER;
    case type === 'campaigns':
      return TRACKER_ACTIONS.CAMPAIGN;
    case type === 'popular_search':
      return TRACKER_ACTIONS.TRENDING_KEYWORD;
    // focus typing actions
    case type === 'virtual_products':
      return TRACKER_ACTIONS.DOPE_SUGGESTION;
    case type === 'brand':
      return TRACKER_ACTIONS.BUKAMALL_SUGGESTION;
    case type === 'history_keywords' && !isDelete:
      return TRACKER_ACTIONS.HISTORICAL_SUGGESTION;
    case type === 'history_keywords' && isDelete:
      return TRACKER_ACTIONS.DELETE_HISTORICAL_SUGGESTION;
    case type === 'category':
      return TRACKER_ACTIONS.CATEGORY_SUGGESTION;
    case type === 'keyword':
      return TRACKER_ACTIONS.ORGANIC_SUGGESTION;
    case type === 'user':
      return TRACKER_ACTIONS.SELLER_SUGGESTION;
    default:
      return undefined;
  }
};

export const generateRevampedTrackerEvent = (action, state, suggestion = {}, ui = '') => {
  const trackerAction = action ?? getActionFromSuggestion(suggestion);
  const trackerObject = TRACKER_OBJECT[state][trackerAction];
  if (typeof trackerObject === 'undefined') return {};

  const object = suggestion[trackerObject];
  return {
    evn: 'omnisearch_action',
    action: trackerAction,
    keyword_before: suggestion.keywordBeforeSelected ? suggestion.keywordBeforeSelected : undefined,
    object: object !== null || object !== '' ? object?.toString() : undefined,
    order: suggestion.order ? suggestion.order?.toString() : undefined,
    ui,
    state,
    bl_username: typeof window.USER === 'undefined' ? '' : window.USER.username,
  };
};

export const fireRevampedTracker = (event, useBeacon) => {
  // do not fire empty event
  if (!event.evn) return;

  const { $config, $userIdentity: identity, $uaData: userAgent } = tracker;
  const timestamp = Date.now();
  const payload = { ...event, t: timestamp, omni_action_id: `${identity}_${timestamp}` };

  if (useBeacon && window.navigator.sendBeacon) {
    // handle tracker requests before navigating to a different page
    // especially to avoid NS_BINDING_ABORTED error in Firefox
    navigator.sendBeacon(`${$config.baseUrl}/e`, JSON.stringify({ ...payload, ...userAgent, identity }));
  } else {
    tracker.event(payload);
  }
};
