const escapeRegExp = str => {
  // eslint-disable-next-line no-useless-escape
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|\<\>]/g, '\\$&');
};

const escapeHtml = unsafe => {
  return unsafe
    .replace(/&/g, `&amp;`)
    .replace(/</g, `&lt;`)
    .replace(/>/g, `&gt;`)
    .replace(/"/g, `&quot;`)
    .replace(/'/g, `&#039;`);
};

const unescapeHtml = safe => {
  return safe
    .replace(/&amp;/g, `&`)
    .replace(/&lt;/g, `<`)
    .replace(/&gt;/g, `>`)
    .replace(/&quot;/g, `"`)
    .replace(/&#039;/g, `'`);
};

const highlightSearch = (message, keyword, flags = 'g') => {
  const newKeyword = keyword;
  let regexWord = '';

  if (typeof keyword === 'string') {
    // when the keyword is empty string, return the original message.
    if (/^\s*$/.test(keyword)) return escapeHtml(message);
    regexWord = escapeRegExp(newKeyword);
  } else if (Array.isArray(keyword) && keyword.length > 0) {
    // when the keyword is empty string, return the original message.
    if (!keyword.filter(() => !/^\s*$/.test(keyword)).length) return escapeHtml(message);
    regexWord = keyword.map(k => escapeRegExp(k)).join('|');
  } else {
    return escapeHtml(message);
  }

  // Can only replace the words out of the html tags.
  const match = new RegExp(`(${regexWord})`, flags);
  const testMath = match.test(message);
  if (testMath) {
    // return escapeHtml(message).replace(match, `<span ${styleString}>\$&</span>`)
    const replaced = message.replace(match, `:;{{:;$&:;}}:;`);
    const matchAgain = new RegExp(`:;{{:;(${escapeHtml(regexWord)}):;}}:;`, flags);
    const restoreReplaced = escapeHtml(replaced).replace(matchAgain, `<span class="highlight">$1</span>`);
    return restoreReplaced;
  }
  return escapeHtml(message);
};

export { highlightSearch, escapeHtml, unescapeHtml };
