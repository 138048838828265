<template>
  <div class="omnisearch-wrapper mt-8">
    <v-omnisearch
      v-model="searchText"
      ref="omnisearch"
      :omnisearch-suggestion="omnisearchSuggestion"
      :omni-credential="omniCredential"
      :search-endpoint="omniUrl"
      :result-order="resultOrder"
      :placeholder="placeholder"
      :is-delete-mode="isDeleteMode"
      @selected="applySuggestion"
      @deleted-keyword-history="handleDeletedKeyword"
      @see-all-suggestion="handleAfterSeeAllSuggestion"
      @focus="setAsFocus"
      @blur="setAsBlur"
      @input="setAsFocusIfNotFirstInput"
      @search="onSearch"
      @submit="handleSubmit"
      @toggle-delete-mode="handleToggleDeleteMode"
      @delete-all-recently-viewed="handleDeleteAllRecentlyViewed"
    />
    <div class="v-omnisearch-snackbar">
      <bl-snackbar-mv
        :active.sync="snackbar.isActive"
        :message="snackbar.message"
        :is-error="snackbar.isError"
      />
    </div>
    <div class="v-omnisearch-overlay" />
  </div>
</template>

<script>
import BlSnackbarMv from '@bukalapak/bazaar-dweb-v2/dist/components/BlSnackbarMv';
import VOmnisearch from './components/VOmnisearch.vue';
import toLower from 'lodash/toLower';
import cloneDeep from 'lodash/cloneDeep';
import trim from 'lodash/trim';
import api from './api';

import { shouldUseProductExplorer, generatePayloads } from 'utils/product-explorer-utils';
import { fireRevampedTracker, generateRevampedTrackerEvent, STATE_BEFORE_TYPING, STATE_FOCUS_TYPING, TRACKER_ACTIONS } from 'utils/omnisearch-revamp-tracker';
import { createOmnisearchTargetUrl, getSearchKeywordParam } from 'utils/url-utils';
import { SECTION, transformPopularSearchSuggestion, transformPopularCategorySuggestion, transformVirtualProductSuggestion, transformCampaignSuggestion } from 'utils/search-utils';
import { MESSAGE, DELETE_HISTORY } from './constants'

const PLACEHOLDER_CHANGE_DURATION = 5000; // milisecond

export default {
  name: 'Omnisearch',
  components: {
    VOmnisearch,
    BlSnackbarMv,
  },
  props: {
    omniUrl: {
      type: String,
      default: 'https://www.bukalapak.com/omniscience/v2',
    },
  },
  data() {
    return {
      searchText: getSearchKeywordParam(window.location.search),
      firstOnInputCalled: false,
      userCategories: [],
      keywordRedirectors: [],
      suggestion: {},
      suggestionBeforeDeleted: {},
      recentlyViewedProducts: [],
      campaignSuggestions: [],
      snackbar: {
        isActive: false,
        isError: false,
        message: '',
        duration: 1000,
      },
    };
  },
  mounted() {
    // remove placeholder on westeros
    const omniPlaceholder = document.getElementById('omnisearch-placeholder');
    if (omniPlaceholder) omniPlaceholder.remove();

    // moved this here from computed so it's only called once
    window.STORE.commit('whitebox/updateKeyword', this.searchText);

    // fetch this once at initial mount so there's no delay when focusing
    this.onSearch(this.searchText);

    this.fetchOmniRevamp();
    this.fetchAndSetUserCategory();
    // FETCH keyword redirector
    setTimeout(() => {
      // delay is meant to prioritise other important request on the page
      this.fetchKeywordRedirectors();
      // why 3s?, average a page being fully loaded
    }, 3000);
  },
  computed: {
    isDeleteMode() {
      return window?.STORE?.state?.whitebox?.isDeleteMode ?? false;
    },
    omniCredential() {
      const fallback = { omnikey: '', user: '' };
      const el = document.querySelector('#omnidata');

      if (!el) return fallback;

      const { dataset } = el;

      if (dataset && dataset.omniKey && dataset.userId) {
        return {
          omnikey: dataset.omniKey,
          user: dataset.userId,
          id36: dataset.userId.toString(36),
        };
      }

      return fallback;
    },
    isInProductDetailPage() {
      const pattern = /\/p\//g;
      return pattern.test(window.location.pathname);
    },
    placeholder() {
      return 'Aku mau belanja...';
    },
    sectionConditionMapper() {
      const hasVirtualProducts = !!this.suggestion.menu?.length
      const hasPopularCategories = !!this.userCategories.length
      const hasKeywordsHistory = !!this.suggestion.keyword?.length
      const hasRecentlyVisitedProducts = !!this.recentlyViewedProducts.length
      const hasRecentlyVisitedSellers = !!this.suggestion.visited_sellers?.length
      const hasCampaignSuggestion = !!this.campaignSuggestions?.length
      const hasRecentlyViewedSection = hasRecentlyVisitedProducts || hasRecentlyVisitedSellers;

      return {
        [SECTION.HISTORY]: true,
        [SECTION.DOPE]: hasVirtualProducts,
        [SECTION.BRANDS]: true,
        [SECTION.CATEGORIES]: true,
        [SECTION.WORDS]: true,
        [SECTION.CATALOG]: true,
        [SECTION.USERS]: true,
        [SECTION.POPULAR_CATEGORIES]: hasPopularCategories,
        [SECTION.KEYWORDS_HISTORY]: hasKeywordsHistory,
        [SECTION.RECENTLY_VIEWED]: hasRecentlyViewedSection,
        [SECTION.PRODUCTS]: hasRecentlyVisitedProducts,
        [SECTION.SELLERS]: hasRecentlyVisitedSellers,
        [SECTION.CAMPAIGNS]: hasCampaignSuggestion,
      }
    },
    beforeTypingResultOrder() {
      const revampedOrder = [SECTION.KEYWORDS_HISTORY, SECTION.RECENTLY_VIEWED, SECTION.PRODUCTS, SECTION.SELLERS, SECTION.CAMPAIGNS]
      return this.filterResultOrder(revampedOrder)
    },
    typingResultOrder() {
      return this.filterResultOrder([SECTION.DOPE, SECTION.BRANDS, SECTION.HISTORY, SECTION.CATEGORIES, SECTION.WORDS, SECTION.CATALOG, SECTION.USERS])
    },
    resultOrder() {
      return !!this.searchText ? this.typingResultOrder : this.beforeTypingResultOrder;
    },
    omnisearchSuggestion() {
      return {
        ...this.suggestion,
        [SECTION.POPULAR_CATEGORIES]: this.userCategories.length ? transformPopularCategorySuggestion(this.userCategories) : [],
        [SECTION.MENU]: this.suggestion.menu?.length ? transformVirtualProductSuggestion(this.suggestion.menu) : [],
        [SECTION.CAMPAIGNS]: transformCampaignSuggestion(this.campaignSuggestions),
        [SECTION.PRODUCTS]: this.recentlyViewedProducts,
      }
    },
    trackerState() {
      return {
        EMPTY: STATE_BEFORE_TYPING,
        TYPING: STATE_FOCUS_TYPING,
      }
    },
  },
  methods: {
    filterResultOrder(sections, mapper = this.sectionConditionMapper) {
      return sections.filter((section) => mapper[section])
    },
    getKeywordOrder(suggestion) {
      const findByKeyword = (keyword) => keyword === suggestion.keyword
      const findBy = (param) => (data) => data[param] === suggestion[param]

      const orderMap = {
        keywords_history: { key: 'keyword', finder: findByKeyword },
        products: { key: 'products', finder: findBy('id') },
        visited_sellers: { key: 'visited_sellers', finder: findBy('id') },
        virtual_products: { key: 'menu', finder: findBy('name') },
        brand: { key: 'brand', finder: findBy('id') },
        history_keywords: { key: 'keyword', finder: findByKeyword },
        category: { key: 'category', finder: findBy('id') },
        keyword: { key: 'word', finder: findByKeyword },
        user: { key: 'user', finder: findBy('id') },
      }

      // make sure to only get orders for selected types
      if (orderMap[suggestion.type]) {
        try {
          const { key, finder } = orderMap[suggestion.type]
          const index = this.omnisearchSuggestion[key].findIndex(finder)

          // only return when found
          return index >= 0 ? index + 1 : undefined
        } catch (error) {
          return undefined
        }
      }
    },
    handleSubmit(isSuggestionSelected) {
      if (!isSuggestionSelected) {
        // only send tracker when no suggestion is being selected (i.e, only by typing) when submitting
        this.sendToEventTrackerRevamped({ keyword: this.searchText }, this.trackerState.TYPING, TRACKER_ACTIONS.ENTER_KEYBOARD, true);
      }
    },
    applySuggestion(suggestion) {
      // do not search if suggestion keyword is empty
      if (suggestion.keyword?.length === 0) return;

      const state = suggestion.keywordBeforeSelected ? this.trackerState.TYPING : this.trackerState.EMPTY
      const suggestionForTracker = Object.assign({}, suggestion);

      if (suggestion.isOnlyByTyping) {
        // by enter
        suggestionForTracker.type = 'select_by_enter';
      }

      suggestionForTracker.order = this.getKeywordOrder(suggestion)
      this.sendToEventTracker(suggestionForTracker, state, null, true);

      // check if exist on keyword redirector list
      const keywordRedirector = this.keywordRedirectors.find((redirector) => toLower(redirector.keyword) === trim(toLower(this.searchText)));

      if (keywordRedirector) {
        const isKeywordFromSuggestion = suggestion.type === 'keyword' && !suggestion.isOnlyByTyping;
        const isKeywordFromHistory = suggestion.type === 'history_keywords';
        const isNeedToUseRedirector = suggestion.isOnlyByTyping || isKeywordFromSuggestion || isKeywordFromHistory;
        const isAllCategorySuggestion = suggestion.type === 'category' && suggestion.category === 'Semua Kategori';
        const isSuggestionHasURL = !!suggestion.url;

        if (isNeedToUseRedirector && !isSuggestionHasURL) {
          if (keywordRedirector.page) {
            // manipulate suggestion url
            suggestion.url = keywordRedirector.page;

            // redirect
            return this.redirectPage(createOmnisearchTargetUrl(suggestion, { isKeywordRedirector: true }));
          }
        }

        if (isAllCategorySuggestion) {
          return this.redirectPage(createOmnisearchTargetUrl(suggestion, { isKeywordRedirector: true }));
        }
      }

      if (shouldUseProductExplorer(suggestion.type)) {
        const payloads = generatePayloads(suggestion);
        window.STORE.commit('whitebox/updateKeyword', suggestion.keyword);
        this.setAsBlur();
        // the handleOmni action is in Dora microFE
        return window.ProductExplorer.$store.dispatch('handleOmni', payloads);
      } else if (suggestion.url) {
        // redirect
        this.redirectPage(createOmnisearchTargetUrl(suggestion));
      } else {
        // redirect to /products
        suggestion.url = `/products?search[keywords]=${encodeURIComponent(this.searchText)}`;
        // redirect
        this.redirectPage(createOmnisearchTargetUrl(suggestion));
      }
    },
    async onSearch(keyword) {
      const response = await api.getSuggestion(keyword);

      this.suggestion = response.data;
    },
    setAsBlur(isExplicit) {
      window.STORE.commit('whitebox/updateIsFocus', false);
      window.STORE.commit('whitebox/toggleIsDeleteMode', false);
      this.onSearch(this.searchText)
      this.fetchOmniRevamp()
      if (isExplicit) {
        // only fire event when the omnisearch is closed explicitly
        this.sendToEventTrackerRevamped({}, STATE_BEFORE_TYPING, TRACKER_ACTIONS.BACK)
      }
    },
    setAsFocus() {
      if (window.STORE.state.whitebox.isFocus) return

      window.STORE.commit('whitebox/updateIsFocus', true);
      this.sendToEventTrackerRevamped({}, STATE_BEFORE_TYPING, TRACKER_ACTIONS.SEARCH_BAR)
    },
    setAsFocusIfNotFirstInput() {
      if (this.firstOnInputCalled) {
        this.setAsFocus();
      } else {
        this.firstOnInputCalled = true;
      }
    },
    redirectPage(targetUrl) {
      window.location.href = targetUrl;
    },
    async fetchOmniRevamp() {
      try {
        this.fetchRecentlyViewedProducts();
        this.fetchCampaignSuggestions();
      } catch (error) {
        // skipped
      }
    },
    fetchRecentlyViewedProducts() {
      api
        .getRecentlyViewedProducts()
        .then(({ data }) => {
          this.recentlyViewedProducts = data;
        })
        .catch((error) => {
          console.error('SEARCH :: Whitebox :: Cannot retrieve recently viewed products');
        });
    },
    fetchCampaignSuggestions() {
      api
        .getCampaignSuggestions()
        .then(({ data }) => {
          this.campaignSuggestions = data ? [data] : [];
        })
        .catch((error) => {
          console.error('SEARCH :: Whitebox :: Cannot retrieve campaign suggestions');
        });
    },
    fetchAndSetUserCategory() {
      // fetch personalized user categories
      api
        .getUserCategories()
        .then(({ data }) => {
          if (!data || data.length < 1) return;
          this.userCategories = data;
        })
        .catch((error) => {
          console.error('SEARCH :: Whitebox :: Cannot retrieve user categories');
        });
    },
    fetchKeywordRedirectors() {
      // is already exist?
      const storageKey = 'Omnisearch:keywordRedirectors';
      const keywordRedirectorFromStorage = sessionStorage.getItem(storageKey) || [];

      if (keywordRedirectorFromStorage.length > 0) {
        const keywordRedirectors = JSON.parse(keywordRedirectorFromStorage);

        this.keywordRedirectors = keywordRedirectors;
      } else {
        api
          .getKeywordRedirectors()
          .then(({ data }) => {
            if (!data || data.length < 1) return;
            this.keywordRedirectors = data;
            // save it for future use
            sessionStorage.setItem(storageKey, JSON.stringify(data));
          })
          .catch((error) => {
            console.error('SEARCH :: Whitebox :: Cannot retrieve keyword redirectors');
            throw error;
          });
      }
    },
    getDeletionParams({ keyword, id }, deletionType) {
      const deletionParams = {
        delete_keywords_history: ['keyword', keyword],
        delete_history_keywords: ['keyword', keyword],
        delete_all_keywords_history: ['keyword'],
        delete_products: [id],
        delete_all_products: ['all'],
        delete_visited_sellers: ['seller', id],
        delete_all_visited_sellers: ['seller'],
      }
      return deletionParams[deletionType]
    },
    deletionCallback(deletionType) {
      // sync the state after deletion
      // currently handling deletion for all items,
      // because single-item deletion is being handled by autosuggest
      switch (deletionType) {
        case 'delete_all_keywords_history':
          this.suggestion = { ...this.suggestion, keyword: [] }
          break;
        case 'delete_all_products':
          this.recentlyViewedProducts = []
          break;
        case 'delete_all_visited_sellers':
          this.suggestion = { ...this.suggestion, visited_sellers: [] }
          break;
        // TODO: sync the state for these as well
        case 'delete_keywords_history':
        case 'delete_products':
        case 'delete_visited_sellers':
        default:
          break;
      }
    },
    restoreDeletion(deletionType) {
      const suggestion = cloneDeep(this.suggestion)
      const recentlyViewedProducts = cloneDeep(this.recentlyViewedProducts)

      switch (deletionType) {
        case 'delete_keywords_history':
        case 'delete_all_keywords_history':
          // had to empty the data first because autosuggest alter the DOM directly, preventing it to be reactive
          this.suggestion = { ...this.suggestion, keyword: [] }
          this.$nextTick(() => this.suggestion = suggestion)
          break;
        case 'delete_products':
        case 'delete_all_products':
          this.recentlyViewedProducts = []
          this.$nextTick(() => this.recentlyViewedProducts = recentlyViewedProducts)
          break;
        case 'delete_visited_sellers':
        case 'delete_all_visited_sellers':
          this.suggestion = { ...this.suggestion, visited_sellers: [] }
          this.$nextTick(() => this.suggestion = suggestion)
          break;
        default:
          break;
      }
    },
    sendDeletionTracker(deletedKeyword, deletionType) {
      const state = deletedKeyword.keywordBeforeSelected ? this.trackerState.TYPING : this.trackerState.EMPTY;

      switch (deletionType) {
        case 'delete_keywords_history':
        case 'delete_history_keywords':
        case 'delete_products':
        case 'delete_visited_sellers':
          const order = this.getKeywordOrder(deletedKeyword);
          return this.sendToEventTracker({ ...deletedKeyword, order, isDelete: true }, state);
        case 'delete_all_keywords_history':
          return this.sendToEventTrackerRevamped({}, state, TRACKER_ACTIONS.DELETE_LAST_SEARCHED_ALL_KEYWORD);
        default:
          break;
      }
    },
    async handleDeletedKeyword(deletedKeyword) {
      const { isDeleteAll, type } = deletedKeyword
      const deletionType = `delete${isDeleteAll ? '_all' : ''}_${type}`

      // do nothing if deletion type is unrecognized
      if (!DELETE_HISTORY[deletionType]) return

      try {
        const { method: deletionMethod, successMessage } = DELETE_HISTORY[deletionType]
        const deletionParams = this.getDeletionParams(deletedKeyword, deletionType) ?? []

        await deletionMethod(...deletionParams)
        this.showSnackbarMessage(successMessage);
        this.deletionCallback(deletionType)
      } catch (error) {
        this.showSnackbarMessage(MESSAGE.ERROR.DELETE_HISTORY, true);
        this.$nextTick(() => this.restoreDeletion(deletionType))
      }

      this.sendDeletionTracker(deletedKeyword, deletionType)
    },
    handleToggleDeleteMode(value) {
      window.STORE.commit('whitebox/toggleIsDeleteMode', value);
      if (this.isDeleteMode) {
        this.sendToEventTrackerRevamped({}, this.trackerState.EMPTY, TRACKER_ACTIONS.DELETE_LAST_SEEN)
      }
    },
    handleDeleteAllRecentlyViewed() {
      this.handleDeletedKeyword({ type: SECTION.PRODUCTS, isDeleteAll: true })
      this.handleDeletedKeyword({ type: SECTION.SELLERS, isDeleteAll: true })
      this.sendToEventTrackerRevamped({}, this.trackerState.EMPTY, TRACKER_ACTIONS.DELETE_LAST_SEEN_ALL);
    },
    handleAfterSeeAllSuggestion(suggestion) {
      const { type, keywordBeforeSelected } = suggestion;
      const seeAllSuggestion = Object.assign(suggestion, {
        type: `see_all_${type}`,
        keywordBeforeSelected,
      });
      this.sendToEventTracker(seeAllSuggestion, this.trackerState.TYPING, TRACKER_ACTIONS.SELLER_SUGGESTION_ALL);
    },

    /**
     * @summary generate event tracker payload and fire it
     * @param {object} suggestion suggestion object to track, also used to determine tracker action for some cases. put empty object when not needed.
     * @param {string} state tracker state, either empty or typing for non-revamped, or before_typing or focus_typing for revamped
     * @param {string} action (optional) event tracker action, only used in revamped version, to override action type regardless of suggestion
     */
    sendToEventTracker(suggestion, state, action, useBeacon) {
        return this.sendToEventTrackerRevamped(suggestion, state, action, useBeacon)
    },
    sendToEventTrackerRevamped(suggestion, state, action, useBeacon) {
      // prevent firing typing state event is searchText is less than 3 letters
      if (state === STATE_FOCUS_TYPING && this.searchText.length < 3) return

      const event = generateRevampedTrackerEvent(action, state, suggestion, this.omniCredential.id36);
      fireRevampedTracker(event, useBeacon);
    },
    showSnackbarMessage(message = '', isError = false) {
      this.snackbar.message = message
      this.snackbar.isError = isError
      this.snackbar.isActive = true
    },
  },
};
</script>
