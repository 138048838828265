<template>
  <div :class="defaultClass" :title="data.name">
    <div class="category-icon">
      <img :src="imgUrl" />
    </div>
  </div>
</template>

<script>
import textHighlight from '../mixins/textHighlight.js';

export default {
  name: 'VPopularCategories',
  mixins: [textHighlight],
  props: {
    data: {
      type: Object,
      required: true,
    },
    defaultClass: {
      type: String,
      default: 'v-omnisearch-result--popular-categories',
    },
  },
  computed: {
    imgUrl() {
      const alphabetOnlyRegex = /[^a-zA-Z]/gi;
      const alphabetOnlyCategoryName = this.data.name && this.data.name.replace(alphabetOnlyRegex, '').toLowerCase();

      return `https://s1.bukalapak.com/category/category-images/w-200/img_${alphabetOnlyCategoryName}.png.webp`;
    },
  },
};
</script>
