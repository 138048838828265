<template>
  <div class="promo" :title="data.name">
    <div class="promo__icon">
      <img width="36" height="36" :src="data.icon" />
    </div>
    <p class="promo__name">{{ data.name }}</p>
    <ico-external-link-minor color="#8D8D8D" />
  </div>
</template>

<script>
import { IcoExternalLinkMinor } from '@bukalapak/bazaar-visual';

export default {
  name: 'VPromo',
  components: { IcoExternalLinkMinor },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

.promo {
  display: flex;
  align-items: center;
  &__image {
    width: 36px;
    height: 36px;
  }
  &__name {
    width: 100%;
    margin-left: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $text-secondary;
    font-size: $font-size-16;
    font-weight: $font-weight-regular;
  }
}
</style>
