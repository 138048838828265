<template>
  <div class="visited-product">
    <transition name="visited-product__delete">
      <div v-if="isDeleteMode" class="visited-product__delete">
        <v-delete-icon />
      </div>
    </transition>
    <div class="visited-product__image" :title="data.name">
      <img :src="image" :alt="data.name" />
    </div>
  </div>
</template>

<script>
import BlThumbnailAv from '@bukalapak/bazaar-dweb-latest/dist/components/BlThumbnailAv';
import VDeleteIcon from './VDeleteIcon.vue';

export default {
  name: 'VVisitedProduct',
  components: { BlThumbnailAv, VDeleteIcon },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    image() {
      return this.data.images?.small_urls?.[0];
    },
    isDeleteMode() {
      return window?.STORE?.state?.whitebox?.isDeleteMode ?? false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

.visited-product {
  display: flex;
  align-items: center;
  padding: 3px;
  &__image {
    position: relative;
    overflow: hidden;
    padding-bottom: 100%;
    width: 100%;
    img {
      position: absolute;
      width: 100%;
      border: 1px solid $border-medium;
      border-radius: $radius-4;
    }
  }
}
</style>

<style lang="scss">
/* global style overrides for vue-autosuggest */
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

.v-omnisearch__results--revamp {
  .-products {
    .autosuggest-result-wrapper {
      display: flex;
      flex-wrap: nowrap;
      margin: 0;
    }
    .autosuggest__result-item {
      box-sizing: border-box;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      -ms-flex-preferred-size: 12.5%;
      flex-basis: 12.5%;
      max-width: 12.5%;
      padding: 0 !important;
      position: relative;
      img {
        height: 100%;
      }
    }
    .visited-product__delete {
      position: absolute;
      top: -3px;
      left: -3px;
      z-index: 1;
      border-radius: 100%;
      background-color: white;

      &-enter-active,
      &-leave-active {
        transition: opacity $speed-fast $ease-in-out-regular;
      }
      &-enter,
      &-leave-to {
        opacity: 0;
      }
    }
  }
}
</style>
