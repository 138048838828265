<template>
  <div>
    <div :class="defaultClass" :title="data.name">
      <div class="result-list__image">
        <img :src="data.img" alt="suggestion image" />
      </div>
      <div v-if="isBrand" class="result-list__icon, result-list__content">
        <p class="result-list__name" v-html="highlight(data.name)" />
        <bl-emblem-av class="bukamall-emblem"  size="small" variation="bukamall_icon" title="bukamall"/>
        <div class="bukamall-icon-text"> BukaMall </div>
      </div>
      <div v-else class="result-list__content">
        <p class="result-list__name" v-html="highlight(data.name)" />
      </div>
    </div>
    <v-section-divider v-if="isBrand" class="brand-section-divider" />
  </div>
</template>
<script>
import textHighlight from '../mixins/textHighlight.js';
// import BlEmblemAv from '@bukalapak/bazaar-dweb-latest/dist/components/BlEmblemAv';
import BlEmblemAv from './BlEmblem.vue';
import VSectionDivider from './VSectionDivider.vue'

export default {
  name: 'VResultList',
  mixins: [textHighlight],
  components: { 
    BlEmblemAv, 
    VSectionDivider,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    defaultClass: {
      type: String,
      default: 'v-omnisearch-result--result-list',
    },
  },
  computed: {
    isBrand() {
      return this.data.type === 'brand';
    },
  },
};
</script>

<style lang="scss">
/* global style overrides for vue-autosuggest */
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

.v-omnisearch__results--revamp {
  .bukamall-emblem {
    position: absolute; 
    margin: 1px 0px 0px -1px; 
    background-position: center; 
    background-size: 16px
  }
  .bukamall-icon-text {
    position: static;
    width: 256px;
    height: 20px;
    font-family: $font-family-default, sans-serif;
    font-size: $font-size-14;
    line-height: $line-height-20;
    color: $text-subdued;
    margin: 2.7px 4px 0px 22px;
    font-weight: $font-weight-regular; 
  }
  .result-list__name {
    font-size: 16px;
    margin-left: 0px; 
  }
  .v-omnisearch-result--result-list .result-list__image {
    width: 40px;
    margin-right: 16px;
    text-align: center;
  }
  .brand-section-divider {
    margin-bottom: 0px; 
    margin-top: 10px;
  }
}
</style>
