<template>
  <div :class="['emblem', `emblem--${variation}`]"></div>
</template>

<script>
// Simple emblem component because Mothership doesn't support the latest bazaar css yet
export default {
  props: {
    variation: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
$superseller_icon: url('https://s0.bukalapak.com/ast/bazaar-dweb/base/images/emblem-new-super-seller-icon.svg');
$brandseller_icon: url('https://s0.bukalapak.com/ast/bazaar-dweb/base/images/emblem-bukamall-icon.svg');

.emblem {
  display: inline-block;
  line-height: 0;
  background-repeat: no-repeat;
  vertical-align: middle;
  border-radius: 50%;
  &--new_superseller_icon {
    background-image: $superseller_icon;
    width: 24px;
    height: 18px;
    background-size: auto;
  }
  &--bukamall_icon {
    background-image: $brandseller_icon;
    width: 20px;
    height: 20px;
    background-size: auto;
  }
}
</style>
