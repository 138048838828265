import qs from 'query-string';
import { getCategorySlug } from './url-utils';

const EXCLUDE_TYPES = [
  'product',
  'user',
  'history',
  'brand',
  'popular_categories',
  'virtual_products',
  'catalog',
  'category', // temporary redirect due to bug https://bukalapak.slack.com/archives/CSFFPV9JA/p1596719011086500
  'campaigns',
  'products',
  'visited_sellers',
];

const addCategoryPayload = (payloads, suggestion) => {
  // if category type and has category id [id 0 means all category]
  const isCategorySuggestion = suggestion.type === 'category' && (suggestion.id || suggestion.id === 0);
  return [
    ...payloads,
    {
      paramLabel: 'search[category_id]',
      paramValue: isCategorySuggestion ? suggestion.id : '',
    },
    {
      paramLabel: 'search[category_slug]',
      paramValue: isCategorySuggestion ? getCategorySlug(suggestion.url) : '',
    },
    {
      paramLabel: 'search[category_name]',
      paramValue: isCategorySuggestion ? suggestion.name : '',
    },
  ];
};

const addSortPayload = (payloads, searchLocation = document.location.search) => {
  const currentParams = qs.parse(searchLocation);
  // empty string means by relavance
  if (!currentParams['search[sort_by]']) {
    return [
      ...payloads,
      {
        paramLabel: 'search[sort_by]',
        paramValue: '',
      },
    ];
  }
  return payloads;
};

const shouldUseProductExplorer = (suggestionType, excludeTypes = EXCLUDE_TYPES) => {
  return window.ProductExplorer && !excludeTypes.includes(suggestionType);
};

const generatePayloads = suggestion => {
  let payloads = [
    {
      paramLabel: 'search[keywords]',
      paramValue: suggestion.keyword,
    },
  ];
  payloads = addSortPayload(payloads);
  payloads = addCategoryPayload(payloads, suggestion);

  return payloads;
};

const useProductExplorer = suggestion => {
  window.BLProductExplorer.activeApp.$store.dispatch('handleOmnisearch', generatePayloads(suggestion));
};

export {
  shouldUseProductExplorer,
  useProductExplorer,
  addCategoryPayload,
  addSortPayload,
  EXCLUDE_TYPES,
  generatePayloads,
};
