<template>
  <div :class="defaultClass" :title="data.name">
    <div class="catalog__image">
      <img :src="data.img" />
    </div>
    <div class="catalog__content">
      <p class="catalog__content-name">
        {{ data.name }}
      </p>
      <div class="catalog__price"><span>Mulai dari</span> Rp{{ data.min_price }}</div>
    </div>
  </div>
</template>

<script>
import textHighlight from '../mixins/textHighlight.js';

export default {
  name: 'VCatalog',
  mixins: [textHighlight],
  props: {
    data: {
      type: Object,
      required: true,
    },
    defaultClass: {
      type: String,
      default: 'v-omnisearch-result--catalog',
    },
  },
};
</script>
