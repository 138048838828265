<template>
  <hr v-if="!isVertical" class="v-omnisearch-result__section-divider" />
  <div v-else class="v-omnisearch-result__section-divider--vertical" />
</template>

<script>
export default {
  name: 'VSectionDivider',
  props: {
    isVertical: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

.v-omnisearch-result__section-divider {
  border: 0;
  border-bottom: 1px solid $border-subtle;
  margin: 8px 0;
  &--vertical {
    height: 100%;
    border-right: 1px solid $border-subtle;
    margin: 0 16px;
  }
}
</style>
