import Omnisearch from './Omnisearch.vue';

const { Vue, APP_SHELL } = global;
const containerEl = '#vm-omnisearch';
const initEvents = ['DOMContentLoaded'];

if (APP_SHELL !== 'westeros') {
  initEvents.push('page:load');
}

function init(element) {
  (() =>
    new Vue({
      el: element,
      name: 'Omnisearch',
      render: h => h(Omnisearch),
    }))();
}

initEvents.forEach(event => {
  document.addEventListener(event, () => {
    if (document.querySelector(containerEl)) {
      init(containerEl);
    }
  });
});

// initial init
init(containerEl);
