import qs from 'query-string';
import urlJoin from 'url-join';

const SECTION = {
  HISTORY: 'history_keywords',
  DOPE: 'virtual_products',
  MENU: 'menu',
  BRANDS: 'brands',
  CATEGORIES: 'categories',
  WORDS: 'words',
  CATALOG: 'catalog',
  USERS: 'users',
  POPULAR_CATEGORIES: 'popular_categories',
  KEYWORDS_HISTORY: 'keywords_history',
  RECENTLY_VIEWED: 'recently_viewed',
  PRODUCTS: 'products',
  SELLERS: 'visited_sellers',
  CAMPAIGNS: 'campaigns',
};

const SUGGESTION_MAPPING = [
  // API-response properties
  {
    from: 'word',
    to: 'keyword',
    sectionName: 'words',
    componentElementName: 'v-keyword',
  },
  {
    from: 'user',
    to: 'user',
    sectionName: 'users',
    componentElementName: 'v-result-list',
  },
  {
    from: 'category',
    to: 'category',
    sectionName: 'categories',
    componentElementName: 'v-keyword',
  },
  {
    from: 'keyword',
    to: 'keywords_history',
    sectionName: 'keywords_history',
    componentElementName: 'v-keyword',
  },
  {
    from: 'keyword',
    to: 'history_keywords',
    sectionName: 'history_keywords',
    componentElementName: 'v-keyword',
  },
  {
    from: 'history',
    to: 'history',
    sectionName: 'histories',
    componentElementName: 'v-keyword',
  },
  {
    from: 'products',
    to: 'products',
    sectionName: 'products',
    componentElementName: 'v-visited-product',
  },
  {
    from: 'visited_sellers',
    to: 'visited_sellers',
    sectionName: 'visited_sellers',
    componentElementName: 'v-visited-seller',
  },
  {
    from: 'campaigns',
    to: 'campaigns',
    sectionName: 'campaigns',
    componentElementName: 'v-promo',
  },
  {
    from: 'catalog',
    to: 'catalog',
    sectionName: 'catalog',
    componentElementName: 'v-catalog',
  },
  {
    from: 'menu',
    to: 'virtual_products',
    sectionName: 'virtual_products',
    componentElementName: 'v-result-list',
  },
  {
    from: 'brand',
    to: 'brand',
    sectionName: 'brands',
    componentElementName: 'v-result-list',
  },
  // custom-built properties
  {
    from: 'popular_categories',
    to: 'popular_categories',
    sectionName: 'popular_categories',
    componentElementName: 'v-popular-categories',
  },
];

const SECTION_CONFIGS = [
  {
    name: 'keywords_history',
    limit: 6,
  },
  {
    name: 'history_keywords',
    limit: 2,
  },
  {
    name: 'current_filter',
    limit: 1,
    label: '',
  },
  {
    name: 'words',
    limit: 5,
  },
  {
    name: 'categories',
    limit: 3,
  },
  {
    name: 'users',
    limit: 2,
  },
  {
    name: 'recently_viewed',
    limit: 0,
  },
  {
    name: 'products',
    limit: 8,
  },
  {
    name: 'visited_sellers',
    limit: 4,
  },
  {
    name: 'campaigns',
    limit: 1,
  },
  {
    name: 'popular_categories',
    limit: 6,
    label: 'Kategori Populer',
  },
  {
    name: 'catalog',
    limit: 2,
  },
  {
    name: 'virtual_products',
    limit: 3,
    label: '',
  },
  {
    name: 'brands',
    limit: 1,
    label: '',
  },
];

const generateSearchParams = (keyword = '', user = undefined, key = undefined) => {
  const params = { word: keyword, omni_revamp: true, user, key };
  const stringified = qs.stringify(params);
  return stringified ? `?${stringified}` : '';
};

const generateParamForDeleteKeyword = (user = undefined, key = undefined, keyword = undefined) => {
  const params = { entity: 'keyword', user, key, entity_id: keyword };
  const stringified = qs.stringify(params);
  return stringified ? `?${stringified}` : '';
};

const addSuggestionProperties = (suggestion, newValue, newProperties = ['name', 'keyword']) => {
  return newProperties.reduce((prev, prop) => ({ ...prev, [prop]: newValue }), suggestion);
};

/* should always contain `keyword` and `type` property on the result */
const addMandatoryProperties = (currentSuggestions, suggestionType) => {
  const result = currentSuggestions.map(suggestion => {
    const suggestionResult = { type: suggestionType };

    switch (true) {
      case typeof suggestion === 'string':
        return addSuggestionProperties(suggestionResult, suggestion);
      default:
        return addSuggestionProperties(Object.assign(suggestion, suggestionResult), suggestion.name, [
          'keyword',
          'name',
        ]);
    }
  });

  return result;
};

const suggestionMapper = (omniscienceSuggestions, suggestionMapping = SUGGESTION_MAPPING) => {
  return suggestionMapping.reduce((prev, { from, to, sectionName }) => {
    return omniscienceSuggestions[from]
      ? { ...prev, [sectionName]: addMandatoryProperties(omniscienceSuggestions[from], to) }
      : { ...prev };
  }, {});
};

const transformSuggestionUrl = suggestions => urlFormat =>
  suggestions.map(suggestion => ({ ...suggestion, url: urlFormat(suggestion) }));

const getUrlPath = url => {
  const anchor = document.createElement('a');
  anchor.href = url;
  return anchor.pathname + anchor.search;
};

const categoryUrlFormatter = suggestion => `/c/${suggestion.permalink}`;
const virtualProductUrlFormatter = suggestion => suggestion.url;
const popularSearchUrlFormatter = suggestion => getUrlPath(suggestion.url);
const transformPopularCategorySuggestion = suggestions => transformSuggestionUrl(suggestions)(categoryUrlFormatter);
const transformVirtualProductSuggestion = suggestions =>
  transformSuggestionUrl(suggestions)(virtualProductUrlFormatter);
const transformPopularSearchSuggestion = suggestions => transformSuggestionUrl(suggestions)(popularSearchUrlFormatter);
const transformCampaignSuggestion = suggestions =>
  suggestions.map(({ display_name: name, landing_page: url, icon }) => ({ name, url, icon }));

const getSuggestionComponentName = (sectionName, mapper = SUGGESTION_MAPPING) => {
  const section = mapper.find(suggestion => suggestion.sectionName === sectionName);
  return (section && section.componentElementName) || 'v-keyword';
};

const generateSeeMoreUrl = (path, keyword, origin = document.location.origin) => {
  const params = { from: 'omnisearch', 'search[keywords]': keyword };
  return urlJoin(origin, path, `?${qs.stringify(params)}`);
};

export {
  SECTION,
  SUGGESTION_MAPPING,
  SECTION_CONFIGS,
  generateSearchParams,
  addMandatoryProperties,
  suggestionMapper,
  addSuggestionProperties,
  transformPopularCategorySuggestion,
  getSuggestionComponentName,
  generateParamForDeleteKeyword,
  generateSeeMoreUrl,
  getUrlPath,
  transformVirtualProductSuggestion,
  transformPopularSearchSuggestion,
  transformCampaignSuggestion,
};
