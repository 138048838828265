const { default: api } = require('./api');

const MESSAGE = {
  SUCCESS: {
    DELETE_RECENTLY_SEARCHED: 'Berhasil dihapus dari Terakhir dicari.',
    DELETE_RECENTLY_VIEWED_SINGLE: 'Berhasil dihapus dari Terakhir dilihat.',
    DELETE_RECENTLY_VIEWED_ALL: 'Berhasil hapus semua riwayat dari Terakhir dilihat',
  },
  ERROR: {
    DELETE_HISTORY: 'Gagal menghapus dari riwayat. Coba lagi ya :)',
  },
};

const DELETE_HISTORY = {
  delete_keywords_history: {
    method: api.deleteHistory,
    successMessage: MESSAGE.SUCCESS.DELETE_RECENTLY_SEARCHED,
  },
  delete_history_keywords: {
    method: api.deleteHistory,
    successMessage: MESSAGE.SUCCESS.DELETE_RECENTLY_SEARCHED,
  },
  delete_all_keywords_history: {
    method: api.deleteAllHistories,
    successMessage: MESSAGE.SUCCESS.DELETE_RECENTLY_SEARCHED,
  },
  delete_products: {
    method: api.deleteRecentlyViewedProduct,
    successMessage: MESSAGE.SUCCESS.DELETE_RECENTLY_VIEWED_SINGLE,
  },
  delete_all_products: {
    method: api.deleteRecentlyViewedProduct,
    successMessage: MESSAGE.SUCCESS.DELETE_RECENTLY_VIEWED_ALL,
  },
  delete_visited_sellers: {
    method: api.deleteHistory,
    successMessage: MESSAGE.SUCCESS.DELETE_RECENTLY_VIEWED_SINGLE,
  },
  delete_all_visited_sellers: {
    method: api.deleteAllHistories,
    successMessage: MESSAGE.SUCCESS.DELETE_RECENTLY_VIEWED_ALL,
  },
};

module.exports = {
  MESSAGE,
  DELETE_HISTORY,
};
