import qs from 'query-string';
import urlJoin from 'url-join';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';

const getCategorySlug = categoryUrl => {
  const regexPattern = /.+?(?=\?)/;
  const matched = regexPattern.exec(categoryUrl);

  if (!matched) return '/products/';

  return matched.length >= 0 && matched[0];
};

const isAbsoluteUrl = (url = '') => {
  const regex = new RegExp('^(?:[a-z]+:)?//', 'i');
  return regex.test(url);
};

const KEYWORD_HISTORY_TYPE = ['keywords_history', 'history_keywords'];

const isKeywordHistory = type => KEYWORD_HISTORY_TYPE.includes(type);

const createOmnisearchTargetUrl = (suggestion, { isKeywordRedirector = false } = {}) => {
  const { url } = suggestion;
  const { query: existingParam, url: path } = qs.parseUrl(url);
  const additionalParams = {
    source: 'navbar',
    from: 'omnisearch',
    search_source: `omnisearch_${suggestion.type}`,
    from_keyword_history: isKeywordHistory(suggestion.type),
  };
  const isSlashProductsPath = path.startsWith('/products'); // /products or /products/s/

  if (isKeywordRedirector && isSlashProductsPath) {
    additionalParams.has_been_redirected = true;
  }

  const resultParam = Object.assign(existingParam, additionalParams);
  const baseUrl = isAbsoluteUrl(url) ? url : urlJoin(document.location.origin, path);

  return urlJoin(baseUrl, `?${qs.stringify(resultParam)}`);
};

const getSearchKeywordParam = (searchParams = document.location.search) => {
  const path = window.location.pathname;
  if (path.includes('/s/')) {
    return (
      path
        .split('/')[3]
        .split('-')
        .join(' ') || ''
    );
  }

  const params = qs.parse(searchParams);

  // this would return array or string
  const searchKeyword = _get(params, 'search[keywords]', '');
  return _isArray(searchKeyword) ? _get(searchKeyword, `[${searchKeyword.length - 1}]`, '') : searchKeyword;
};

export { getCategorySlug, createOmnisearchTargetUrl, getSearchKeywordParam, isKeywordHistory, isAbsoluteUrl };
