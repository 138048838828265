import { highlightSearch } from '@/utils/highlight-utils';

export default {
  props: {
    keyword: {
      type: String,
      default: '',
    },
  },
  methods: {
    highlight(name = '') {
      return highlightSearch(name, this.keyword, 'g');
    },
  },
};
