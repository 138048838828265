<template>
  <component ref="icon" title="delete" :is="icon" :color="color" />
</template>

<script>
import { IcoUiCross, IcoCrossCircleMinor } from '@bukalapak/bazaar-visual';

const ICON_MAPPER = {
  primary: {
    icon: 'ico-cross-circle-minor',
    color: '#D1083A',
  },
  neutral: {
    icon: 'ico-ui-cross',
    color: '#8D8D8D',
  },
};

const DELETE_CLASS = 'v-omnisearch-result__delete';

export default {
  name: 'VDeleteIcon',
  components: { IcoUiCross, IcoCrossCircleMinor },
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator: (value) => Object.keys(ICON_MAPPER).includes(value),
    },
  },
  computed: {
    icon() {
      return ICON_MAPPER[this.variant].icon;
    },
    color() {
      return ICON_MAPPER[this.variant].color;
    },
  },
  mounted() {
    // manually inject this class to the svg element,
    // because autosuggest component will look for this class to emit the delete event from
    this.$refs.icon.$el.classList.add(DELETE_CLASS);
    this.$refs.icon.$el.children[0].classList.add(DELETE_CLASS);
  },
};
</script>
