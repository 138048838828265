<template>
  <div :class="classNames" :title="data.name">
    <div class="popular-search__image">
      <img :src="data.thumbnail_url" />
    </div>
    <p class="popular-search__name">{{ data.keyword }}</p>
  </div>
</template>

<script>
import textHighlight from '../mixins/textHighlight.js';

export default {
  name: 'VPopularSearch',
  mixins: [textHighlight],
  props: {
    data: {
      type: Object,
      required: true,
    },
    defaultClass: {
      type: String,
      default: 'v-omnisearch-result--popular-search',
    },
  },
  computed: {
    classNames() {
      return [this.defaultClass, 'v-omnisearch-result--revamp']
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

.v-omnisearch-result--revamp {
  border: unset;
  .popular-search {
    &__image {
      height: 100%;
    }
    &__name {
      max-width: unset;
      padding: 0 8px;
      color: $text-secondary;
      font-size: $font-size-16;
      font-weight: $font-weight-regular;
    }
  }
}
</style>

<style lang="scss">
/* global style overrides for vue-autosuggest */
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

.v-omnisearch__results--revamp {
  .-popular_search {
    .autosuggest-result-wrapper {
      margin: 0;
    }
  }
}
</style>
