const interfaces = {
  getUserCategories() {
    return this.get('/personalizations/user-categories');
  },
  getKeywordRedirectors() {
    return this.get('/info/keyword-redirectors');
  },
  getCampaignSuggestions() {
    return this.get('/placeholder-suggestions');
  },
  getRecentlyViewedProducts() {
    return this.get('/products/recently-viewed', 'public', {
      params: { limit: 8 },
    });
  },
  deleteRecentlyViewedProduct(id) {
    // same endpoint, but to delete all items, id = 'all'
    return this.delete(`/products/recently-viewed/${id}`, 'public');
  },
  getSuggestion(keyword) {
    return this.get('/searches/suggestions', 'public', {
      params: {
        word: keyword,
      },
    });
  },
  deleteAllHistories(entity = 'keyword') {
    return this.delete('/searches/histories', 'public', {
      params: { entity },
    });
  },
  deleteHistory(entity = 'keyword', id) {
    return this.delete('/searches/history', 'public', {
      params: { entity, entity_id: id },
    });
  },
  getToggles(id) {
    return this.post('/_exclusive/neo/toggles', 'public', {
      data: { id, platform: 'bl_web' },
    });
  },
};
const wrapSecureClientInterface = global.API.wrap;

export default wrapSecureClientInterface(interfaces);
