<template>
  <div class="v-omnisearch__before-result">
    <h3 class="section-title__text">{{ title }}</h3>
    <slot v-if="$slots.default" />
    <div v-else class="section-title__link" @click="$emit('action')">{{ action }}</div>
  </div>
</template>

<script>
export default {
  name: 'VSectionHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
/* scoped and coupled styles for VSectionHeader */
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

.v-omnisearch__before-result {
  margin-top: unset;
  padding: unset;
  .section-title__text {
    font-family: $font-family-heading;
    font-size: $font-size-20;
    color: $border-foreground;
    text-transform: unset;
  }
  .section-title__link {
    font-family: $font-family-default;
    font-size: $font-size-16;
    color: $text-secondary;
    text-decoration: unset;
    &--blue {
      color: $link-01;
    }
  }
}
</style>

<style lang="scss">
/* global style overrides for vue-autosuggest */
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

.v-omnisearch__results--revamp {
  .autosuggest__result-item {
    padding: 8px !important;
    &--highlighted {
      border-radius: $radius-4;
    }
  }
}
</style>
