<template>
  <div :class="classNames" :title="data.name">
    <div class="result-image-box">
      <component :is="icon" color="#B6B6B6" />
    </div>
    <div class="result-name" v-html="genContent" />
    <v-delete-icon v-if="this.showDelete" variant="neutral" class="v-omnisearch-result__delete history-delete" />
  </div>
</template>

<script>
import { IcoHistory, IcoSearch } from '@bukalapak/bazaar-visual';
import VDeleteIcon from './VDeleteIcon.vue';

import dompurify from 'dompurify';
import textHighlight from '../mixins/textHighlight.js';

export default {
  name: 'VKeyword',
  components: { VDeleteIcon, IcoHistory, IcoSearch },
  mixins: [textHighlight],
  props: {
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: '',
    },
    defaultClass: {
      type: String,
      default: 'v-omnisearch-result--keyword',
    },
  },
  computed: {
    classNames() {
      return [this.defaultClass, 'v-omnisearch-result--revamp'];
    },
    genContent() {
      let content = this.highlight(this.data.keyword);
      if (this.type === 'categories') {
        content += ` <span class="category-tag">di ${this.data.category}</span>`;
      } else if (this.type === 'current_filter') {
        content += ` <span class="filter-txt-gray">dalam Filter saat Ini</span>`;
      }

      return dompurify.sanitize(content, { ALLOWED_TAGS: ['span'] });
    },
    showDelete() {
      return this.data.showDelete || false;
    },
    icon() {
      return this.showDelete ? 'ico-history' : 'ico-search';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

.v-omnisearch-result--revamp {
  justify-content: unset;
  align-items: center;
  .result-name {
    font-size: $font-size-16;
    line-height: $line-height-24;
    width: 100%;
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $text-secondary;
  }
  .ico_history,
  .ico_ui_cross {
    min-width: $space-24;
  }
  .result-image-box {
    width: $space-40;
    text-align: center;
    flex-shrink: 0;
    margin-right: $space-16;
  }
}
</style>

<style lang="scss">
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

.v-omnisearch-result--revamp {
  .category-tag {
    left: 4px;
    top: 4px;
    font-family: $font-family-default;
    font-size: $font-size-10;
    color: #141414;
    margin: 0 10px 0 2.4px;
    background-color: $background-low;
    border-radius: $radius-4;
    padding: $space-4;
  }
}
</style>
