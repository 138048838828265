<template>
  <vue-autosuggest
    class="v-omnisearch"
    ref="v-omnisearch"
    v-model="searchText"
    :input-props="inputProps"
    component-attr-id-autosuggest="v-omnisearch"
    component-attr-class-autosuggest-results-container="v-omnisearch__results_container--revamp"
    component-attr-class-autosuggest-results="v-omnisearch__results--revamp"
    :section-configs="sectionConfigs"
    :suggestions="suggestions"
    :render-suggestion="renderSuggestion"
    :should-render-suggestions="shouldRenderSuggestions"
    @selected="onSelected"
    @deleted="onDeleted"
    @input="onInputChange"
    @focus="onFocus"
    @keydown="handleKeyStroke"
    :placeholder="placeholder"
    :class="{ 'v-omnisearch--short': height === 'short' }"
  >
    <template slot="before-input">
      <label
        for="v-omnisearch__input"
        class="v-omnisearch__label"
        aria-label="Hapus kata kunci"
        :class="this.isSearchTextExist ? '-on-focus' : ''"
        @click="deleteSearchText"
      >
        <ico-ui-cross />
      </label>
      <input
        autocomplete="off"
        :value="hint"
        class="v-omnisearch__input -hint"
        type="text"
        readonly
        spellcheck="false"
        tabindex="-1"
      />
      <!-- disable when no-keyword given, also disable on enter -->
      <button
        :disabled="!this.isSearchTextExist"
        :class="submitButtonClass"
        tabindex="-1"
        title="Cari"
        @click="onSelected()"
        type="submit"
      >
        <ico-search />
      </button>
    </template>
    <template slot="before-section" slot-scope="{ section }">
      <template>
        <v-section-header v-if="section.name === SECTION.KEYWORDS_HISTORY" title="Terakhir dicari" action="Hapus Semua" @action="deleteAllHistory" />
        <v-section-header v-else-if="section.name === SECTION.RECENTLY_VIEWED" title="Terakhir dilihat di aplikasi">
          <span v-if="!isDeleteMode" class="section-title__link" @click="$emit('toggle-delete-mode')">Hapus</span>
          <div v-else class="delete-recently-viewed">
            <span class="section-title__link" @click="deleteAllRecentlyViewed">Hapus Semua</span>
            <v-section-divider is-vertical/>
            <a class="section-title__link section-title__link--blue" @click="$emit('toggle-delete-mode')">Selesai</a>
          </div>
        </v-section-header>
        <v-section-header v-else-if="section.name === SECTION.CAMPAIGNS" title="Promo terbaru" />
        <template v-else-if="section.name === SECTION.USERS && section.data.length > 0 && !section.label">
          <v-section-divider class="user-header-divider"/>
          <v-section-header title="Pelapak">
          </v-section-header>
        </template>
      </template>
    </template>
  </vue-autosuggest>
</template>

<script>
import VueAutosuggest from '@bukalapak/vue-autosuggest/src/Autosuggest.vue';
import { IcoSearch, IcoUiCross } from '@bukalapak/bazaar-visual'

import {
  SECTION_CONFIGS,
  suggestionMapper,
  addMandatoryProperties,
  getSuggestionComponentName,
  addSuggestionProperties,
  generateSeeMoreUrl,
  SECTION,
} from '@/utils/search-utils';

import VSectionHeader from './VSectionHeader.vue';
import VKeyword from './VKeyword.vue';
import VResultList from './VResultList.vue';
import VPopularSearch from './VPopularSearch.vue';
import VVisitedProduct from './VVisitedProduct.vue';
import VVisitedSeller from './VVisitedSeller.vue';
import VPromo from './VPromo.vue';
import VPopularCategories from './VPopularCategories.vue';
import VCatalog from './VCatalog.vue';
import VSectionDivider from './VSectionDivider.vue';

export default {
  name: 'VOmnisearch',
  components: {
    IcoSearch,
    IcoUiCross,
    VueAutosuggest,
    VSectionHeader,
    VKeyword,
    VResultList,
    VPopularSearch,
    VVisitedProduct,
    VVisitedSeller,
    VPromo,
    VPopularCategories,
    VCatalog,
    VSectionDivider,
  },
  props: {
    omnisearchSuggestion: {
      type: Object,
      default: () => ({}),
    },
    searchEndpoint: {
      type: String,
      default() {
        return 'https://www.bukalapak.com/omniscience/v2';
      },
    },
    formClassIdentifier: {
      type: String,
      default: () => 'js-omnisearch-form',
    },
    iconClass: {
      type: String,
      default: 'v-omnisearch__label-icon',
    },
    submitButtonClass: {
      type: String,
      default: 'v-omnisearch__submit',
    },
    omniCredential: {
      type: Object,
      default: () => ({}),
    },
    resultOrder: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Aku mau belanja...',
    },
    height: {
      type: String,
      default: () => 'normal',
      validator: (val) => ['short', 'normal', 'tall'].includes(val),
    },
    omnisearchWrapperClass: {
      type: String,
      default: () => 'omnisearch-wrapper',
    },
    deleteEndpoint: {
      type: String,
      default: () => 'https://www.bukalapak.com/omniscience/delete',
    },
    isDeleteMode: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    sectionConfigs() {
      const onSelected  = ({ item }) => this.onSelected(item)
      const sectionConfigs = SECTION_CONFIGS.reduce((prev, curr) => ({ ...prev, [curr.name]: { ...curr, onSelected } }), {})

      const numHistoricalResults = this.omnisearchSuggestion?.keyword?.length ?? 0
      sectionConfigs.words.limit = 6 - Math.min(numHistoricalResults, 2)

      sectionConfigs.default = { onSelected: this.onSelected }
      return sectionConfigs
    },
    seeMoreCatalog() {
      return generateSeeMoreUrl('/catalogs', this.searchText);
    },
    isSearchTextExist() {
      return !!this.searchText;
    },
  },
  data() {
    return {
      searchText: this.value ?? '',
      hint: '',
      inputProps: {
        id: 'v-omnisearch__input',
        class: 'v-omnisearch__input',
        name: 'search[keywords]',
      },
      suggestions: [],
      wrapper: null,
      isFocus: false,
      SECTION: SECTION,
    };
  },
  watch: {
    searchText(newKeyword) {
      this.$emit('input', newKeyword ? newKeyword : '');
    },
    omnisearchSuggestion: {
      deep: true,
      handler(newSuggestion) {
        this.setSuggestions(this.resultMapper(newSuggestion, this.searchText), this.resultOrder);
      },
    },
  },
  methods: {
    resultMapper(omniResponse, keyword = '') {
      let result = suggestionMapper(omniResponse);
      if (keyword && !result.words?.length) {
        result.words = addMandatoryProperties([keyword], 'keyword');
      }

      if (result.keywords_history) {
        result.keywords_history = result.keywords_history.map((suggestion) =>
          addSuggestionProperties(suggestion, true, ['showDelete']),
        );
      }

      if (result.history_keywords) {
        result.history_keywords = result.history_keywords.map((suggestion) =>
          addSuggestionProperties(suggestion, true, ['showDelete']),
        );
      }

      return result;
    },
    onInputChange(value) {
      this.search(value);
      this.clearHintIfInvalid();
    },
    onFocus() {
      this.isFocus = true
      this.search(this.searchText);
      this.$emit('focus');
    },
    onBlur(isExplicit) {
      this.isFocus = false
      this.$emit('blur', isExplicit);
    },
    search(value = '') {
      this.$emit('search', value);
      this.$emit('toggle-delete-mode', false)
    },
    setSuggestions(data, orders) {
      const suggestions = [];
      orders.forEach((order, index) => {
        // set hint as first suggestion order
        if (order === 'categories') {
          this.setHint(data['categories']);
          this.clearHintIfInvalid();
        }

        suggestions.push({ name: order, data: data[order] ?? [] });
      });

      this.suggestions = suggestions;
    },
    handleKeyStroke(e) {
      if (!e) return;

      const { keyCode } = e;

      if (keyCode === 40 || keyCode === 38) {
        this.clearHintIfInvalid();
      }

      if (keyCode === 39 && this.hint) {
        this.searchText = this.hint;
        this.search();
      }
    },
    setHint(data) {
      if (data?.[0]?.keyword) {
        this.hint = data[0].keyword.toLowerCase();
      }
    },
    clearHintIfInvalid() {
      const internalValue = this.$refs['v-omnisearch'].internalValue;
      const valIsPrefixOfHint = this.searchText !== this.hint && this.hint.indexOf(this.searchText) === 0 && internalValue !== this.hint;
      const isValid = this.searchText !== '' && valIsPrefixOfHint;

      !isValid && this.clearHint();
    },
    clearHint() {
      this.hint = '';
    },
    onDeleted(deleted) {
      if (deleted.item.keyword) {
        this.$emit('deleted-keyword-history', { ...deleted.item, keywordBeforeSelected: this.searchText });
      }
    },
    deleteAllHistory() {
      this.$emit('deleted-keyword-history', { type: SECTION.KEYWORDS_HISTORY, isDeleteAll: true });
    },
    deleteAllRecentlyViewed() {
      this.$emit('delete-all-recently-viewed');
    },
    deleteSearchText() {
      this.searchText = '';
      this.clearHintIfInvalid();
    },
    onSelected(suggestion = {}) {
      let selectedSuggestion = { ...suggestion };
      const keywordBeforeSelected = this.searchText;

      const isSuggestionExist = suggestion !== null && Object.keys(suggestion).length > 0;
      this.$emit('submit', isSuggestionExist);
      if (!isSuggestionExist) {
        // when user pressed enter (not selecting any suggestion)
        // inject suggestion keyword and type here
        selectedSuggestion = addMandatoryProperties([this.searchText], 'keyword')[0];
      } else {
        // to update to the DOM
        this.searchText = selectedSuggestion.keyword;
      }

      selectedSuggestion.isOnlyByTyping = !isSuggestionExist;

      // wait till DOM updated
      this.$nextTick(() => {
        this.$emit('selected', { ...selectedSuggestion, keywordBeforeSelected });
        this.clearHintIfInvalid();
        if (this.searchText) this.onBlur()
      });
    },
    shouldRenderSuggestions(size, loading) {
      return this.isFocus || (size >= 0 && !loading);
    },
    renderSuggestion(suggestion) {
      let element;
      const suggestionName = suggestion.name;
      const props = {
        data: suggestion.item,
        keyword: this.searchText,
        type: suggestionName,
      };
      const elementName = getSuggestionComponentName(suggestionName);
      element = this.$createElement(elementName, { props });

      return element;
    },
    clickSubmitButton() {
      this.$nextTick(() => {
        // strengly doen't work with getElementByClassName
        document.querySelector('.v-omnisearch__submit').click();
      });
    },
    onDocumentMouseUp(e) {
      /* Clicks outside of dropdown */
      const isBackdrop = e.target.classList.contains('backdrop')
      if (isBackdrop) this.onBlur(true)
    },
  },
  mounted() {
    this.wrapper = document.querySelector('.' + this.omnisearchWrapperClass);
    document.addEventListener('mouseup', this.onDocumentMouseUp);
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.onDocumentMouseUp);
  },
};
</script>

<style lang="scss" src="./VOmnisearch.scss" />

<style lang="scss">
.user-header-divider {
  margin-bottom: 10px;
}

.delete-recently-viewed {
  display: flex;
  height: 20px;
}
</style>
